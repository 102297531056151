import "./App.css";
import Navbar from "./components/Navbar.component";
import Welcome from "./components/Welcome.component";
import Works from "./components/Works.component";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
	return (
		<>
			<Router>
				<Navbar></Navbar>
				<Switch>
					<Route path="/" exact>
						<Welcome />
					</Route>
					<Route
						path="/logonz"
						exact
						component={() => {
							window.location.href = "https://logonz.ml";
							return null;
						}}
					/>
					<Route path="/works" exact>
						<Works />
					</Route>
					<Route>
						<h2>404 Page Not Found</h2>
					</Route>
				</Switch>
			</Router>
		</>
	);
}

export default App;
