import logonzLight from "../assets/Logonz-light.svg";
import logonzDark from "../assets/Logonz-dark.svg";
import useMediaQuery from "./MediaQueries";

const Works = () => {
	const darkTheme = useMediaQuery("(prefers-color-scheme: dark)") ? true : false;

	return (
		<section>
			<div>
				<div>
					<h2>Logonz</h2>
					<p>Logonz is a completely free password manager that lets you store all your passwords in the cloud and access them from anywhere. Logonz is available for your browser, your phone, and for everything else you can use Logonz website.</p>
				</div>
				<div>
					<img id="logonz" src={darkTheme ? logonzDark : logonzLight} alt="Logonz Logo" />
				</div>
			</div>
			<div>
				<div>
					<h2>Dawyne</h2>
					<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad beatae velit earum modi fuga molestiae non est quam itaque ex.</p>
				</div>
				<div>
					<img id="logonz" src={darkTheme ? logonzDark : logonzLight} alt="Logonz Logo" />
				</div>
			</div>
		</section>
	);
};

export default Works;
