import { Link } from "react-router-dom";

const Navbar = () => {
	return (
		<nav>
			<Link to="" className="brand">
				Stron
			</Link>
			<a href="/works" className="works">
				Works
			</a>
		</nav>
	);
};

export default Navbar;
