import Image from "../assets/Image.png";

const Welcome = () => {
	return (
		<section>
			<div className="wai">
				<div>
					<h2>Who am I??</h2>
					<p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quaerat dolor suscipit, accusantium, minus repellendus rem sunt a doloribus corrupti eum deleniti corporis, atque molestiae facere.</p>
				</div>
				<div>
					<img id="profile" src={Image} alt="STron" />
				</div>
			</div>
		</section>
	);
};

export default Welcome;
